import gmail from '../assets/images/gmail.svg'

export default [
    {
        id: 1,
        title: "responsetoshivani@gmail.com",
        icon: gmail,
        linkTo: false,
    },
];
